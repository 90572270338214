export const COMPANY_USER_ROLE_FOR_CALENDAR_FRAGMENT = `
  fragment companyUserRoleForCalendarFragment on CompanyUserRole {
    uid
    user {
      uid
      firstName
      lastName
      prefix
      suffix
      jobTitle
      pictureFileResource {
        schemaCode
        path
      }
    }
  }
`;
